// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/blog-collection-list.css';

YUI.add('squarespace-blog-collection-list', function (Y) {

  var win = Y.config.win;
  var doc = win.document;

  // Min width of each column, 200 by default
  var MIN_WIDTH = 200;

  // Shortcut for getTweakValue
  var getTweakValue = Y.Squarespace.Template.getTweakValue;

  // Calculate column count
  var syncColumns = function (blogList) {
    if (!blogList) {
      return;
    }

    // Settings from tweak
    var cols = parseInt(getTweakValue('tweak-blog-list-columns'), 10);
    var gutter = parseInt(getTweakValue('tweak-blog-list-spacing'), 10);

    // Container width from passed-in blogList
    var containerWidth = parseFloat(win.getComputedStyle(blogList).width);

    // Starting with user-set column count, decrement
    // until the content fits in the container
    while ((containerWidth - (gutter * (cols - 1))) / cols < MIN_WIDTH) {
      cols--;
    }

    // Set data-attribute on columns, which affects
    // styling
    blogList.setAttribute('data-columns', cols);

  };

  // Sync images based on aspect ratio option
  var syncImages = function (blogList) {

    if (!blogList) {
      return;
    }

    // Array of images
    var images = blogList.querySelectorAll('.BlogList-item-image img');

    if (getTweakValue('tweak-blog-list-item-image-show') === 'true') {

      var isStacked = getTweakValue('tweak-blog-list-style').toLowerCase() === 'stacked';
      var isAuto = getTweakValue('tweak-blog-list-item-image-aspect-ratio-stacked').toLowerCase() === 'auto';

      if (isStacked && isAuto) {

        // Load images
        for (var i = 0; i < images.length; i++) {

          var img = images[i];

          img.style.position = null;
          img.style.top = null;
          img.style.right = null;
          img.style.bottom = null;
          img.style.left = null;
          img.style.width = null;
          img.style.height = null;

          win.ImageLoader.load(img, {
            load: true,
            mode: null
          });

        }

      } else {

        // Load images
        for (var j = 0; j < images.length; j++) {

          win.ImageLoader.load(images[j], {
            load: true,
            mode: 'fill'
          });

        }

      }
    }
  };

  var sync = function (blogList) {

    syncColumns(blogList);
    syncImages(blogList);

  };

  // Sync on resize
  var RESIZE_TIMEOUT = 100;
  var isDragging = false;
  var _resizeMeasureTimer;
  var handleResize = function (blogList) {
    if (!isDragging) {
      isDragging = true;
    }

    if (_resizeMeasureTimer) {
      clearTimeout(_resizeMeasureTimer);
    }

    _resizeMeasureTimer = setTimeout(function () {
      // Navigated and could not clear timeout
      if (!Y || !Y.config || !Y.config.win) {
        return;
      }
      sync(blogList);
      isDragging = false;
    }, RESIZE_TIMEOUT);
  };


  // Sync on tweak change
  var columnSyncTweaks = [
    'tweak-blog-list-columns',
    'tweak-blog-list-spacing'
  ];
  var imageSyncTweaks = [
    'tweak-blog-list-style',
    'tweak-blog-list-columns',
    'tweak-blog-list-spacing',
    'tweak-blog-list-item-image-aspect-ratio-grid',
    'tweak-blog-list-item-image-aspect-ratio-stacked',
    'tweak-blog-list-item-image-show',
    'tweak-blog-list-item-image-width'
  ];
  var handleTweak = function (e) {
    var tweakName = e.getName();

    if (columnSyncTweaks.indexOf(tweakName) >= 0) {
      syncColumns();
    }
    if (imageSyncTweaks.indexOf(tweakName) >= 0) {
      syncImages();
    }
  };

  Y.Global.on('tweak:change', handleTweak);


  var blogList;
  var handleResizeWithParams;

  Y.config.win.Squarespace.onInitialize(Y, function () {
    blogList = doc.querySelector('.BlogList');
    if (blogList) {
      sync(blogList);
      handleResizeWithParams = handleResize.bind(win, blogList);
      win.addEventListener('resize', handleResizeWithParams);
    }
  });


  // Destructor
  Y.config.win.Squarespace.onDestroy(Y, function () {
    win.removeEventListener('resize', handleResizeWithParams);
  });

}, '1.0', {
  requires: []
});
